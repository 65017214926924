.buttonCardServ{
    background-color: white;
    color: black;
    border: 2px solid #4CAF50; /* Green */
    padding: 10px;
}

.buttonCardServ:hover {
    background-color: #4CAF50; /* Green */
    color: white;
}

.Contenido h4{
    color:rgb(17, 122, 87);
    font-weight: bold
}