

.IconRedes{
    color: white;
    font-size: 3rem;
}


.barra {
    color:#8f8d8d;
    font-size: 0.9rem;
    line-height: 1.5rem;
    text-align: justify;
    background-color:#dcdcdc;
    padding-bottom: 1rem;
}


.navegacion {
    
    text-align: center;
}


/* pantalla grande */
@media (min-width: 768px) {

    .barra {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 2rem;
        padding-left: 2rem;
    }
    
}

/* pantalla grande */
@media (min-width: 768px) {
    .navegacion {
        display: flex;
        gap: 1rem;
        text-align: center;
    }
    
}
/* .navegacion__enlace {
    display: block;
    text-align: center;
    font-size: 1.8rem;
    color: var(--blanco);
} */


.iconoTec{
    height: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
}


.containerIcons img{
    margin-top: 10px;
    margin-right: 10px;
}

.containerInfo{
    padding: 15px;
}