.containerOurServ{
	padding: 1rem;
}

.titServ h3{
text-align: center;
}

.IconService{
    font-size: 25px;
    color: #5F9EA0;
    margin-left: 5px;
}
.servicio{
    display: grid;
    grid-template-columns: auto auto;
  
}
    
.servicio h5{
        color:black;
        font-size: 1rem;
        font-family: "ArIaL", serif;
        /* font-family: Garamond, serif; */
}
    
.servicio h3{
        margin-top: 10px;
		text-align: center;
}



.servSitioWeb p{
    
        margin-top: 10px;
        text-align: justify;
        color: #999898;
        font-family: "ArIaL", serif;
        /* font-family: Garamond, serif; */
        padding: 1.2rem;
}

.Card{
        margin-top: 15px;
        padding: 1rem;
        
}
    
.Card p,a{
        text-align: justify;
        /* font-family: Garamond, serif; */
        font-family: "ArIaL", serif;
        color: #2F4F4F;
        padding: 1.2rem;
}

.Card img{
    width: 100%;

    /* La etiqueta img por defecto se comporta como un elemento inline, si quieres alinearla al centro basta con establecer su valor en de 
    display en block y agregar un margen automático para ponerla en el centro tal y como lo haríamos con cualquier otra etiqueta */
    display: block;
    margin: auto;
    height: 290px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.fixed-width-card{
	width: 18rem;
}

/* ESCRITORIO */
@media (min-width: 768px) {
    .Card img {
		width: 50%;

		/* La etiqueta img por defecto se comporta como un elemento inline, si quieres alinearla al centro basta con establecer su valor en de 
		display en block y agregar un margen automático para ponerla en el centro tal y como lo haríamos con cualquier otra etiqueta */
		display: block;
		margin: auto;
		height: 290px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		margin-top: 10px;
    }
	.ContainerServ{
		padding-left: 4rem;
		padding-right: 4rem;
	}
	.containerOurServ{
		padding-left: 4rem;
		padding-right: 4rem;
		text-align: justify;
		line-height: 2.5rem;
	}
    
}


    
.containerOurServ p,
li,
h4 {
  color: #8f8d8d;
  line-height: 2rem;
  text-align: justify;
}


.CardCont {
    border: 1px solid #eeeaea; 

}

.CardCont p{
 color: #999898;
 line-height: 2rem;
}


.Card h5{
        color: black;
}

*{
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body{
  background: #1D1F20;
}

.wrap{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px,1fr));
	/* width: 1100px; */
	margin-top: 10px;
text-align: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	
	/* el contenedor se esta tirando hacia la derecha */

}

.tarjeta-wrap{
	grid-template-columns: repeat(auto-fit, minmax(220px,1fr));
	margin: 10px;
	-webkit-perspective: 800;
	perspective: 800;
	display: grid;
	gap:10px;
	text-align: center;
	margin-left: 10px;
    margin-right: 10px;
    
}

.tarjeta{
	width: 100%;
	height: 350px;
	background: #fffefe;
	position: relative;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition: .7s ease;
	transition: .7s ease;
	-webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
	box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
	gap: 15px;
	justify-content: space-between;
	
}

.adelante, .atras{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.adelante{
	width: 100%;
}

.atras{
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
    margin-top: 12px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;

	text-align: center;
	color: #fff;
	font-family: "open sans";
  background: white;
} 

.tarjeta-wrap:hover .tarjeta{
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
}

.card1{
	background-image: url(./imagenes/monedero.png);
	display: block;
    padding: 1rem;
	margin: 2rem auto;
    height: 290px;
    background-position: center;
    background-repeat: no-repeat;
}

.card2{
	background-image: url(./imagenes/cardEcom.png);
	display: block;
    padding: 1rem;
	margin:2rem auto;

    height: 290px;
   
    background-position: center;
    background-repeat: no-repeat;
}

.card3{
	background-image: url(./imagenes/products.png);
	display: block;
    padding: 1rem;
	margin:2rem auto;

    height: 290px;
   
    background-position: center;
    background-repeat: no-repeat;
}

.ContainerCards{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px,1fr)); 
	gap:15px;

	/* width: 1100px; */
	margin: 50px auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox; 
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}


.CardsIndiv{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
	border: 1px solid #eeeaea; 


	/* tarjeta-wrap */
	margin: 10px;
	-webkit-perspective: 800;
	perspective: 800;
    padding-left: 1rem;
    padding-right: 1rem;
	display: grid;
	gap:15px;
	grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
}