
.CardCompany{
    
    background-color: white;
    /* para que todo elemento que este fuera del contenedor card se oculte y no aparezca afuera*/
    overflow: hidden;
    transition:all 400ms ease;
    margin-top: 10px;
    border: 1.19px solid rgba(224, 224, 224, 1);
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;

    /* estilo a las imagenes */
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
}


.CardCompany img{
    width:100%;
    height: 180px;
}

.CardCompany .Contenido h4{
font-size: 1rem;
text-align: center;
text-decoration: none;
margin-top: 10px;
font-weight: 400;
margin-bottom: 10px;
}

.CardCompany .Contenido p{
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    /* espacio por cada parrafo */
    line-height: 1.5;
    color:#6a6a6a
    }

  