.containerDetails{
   
    text-align: center;
    padding: 20x;
    margin-top: 15px;
    margin: auto;
}

.containerDetailsInt{
  display: grid;
  grid-template-columns: 5% 92%;
  margin-top: 15px;
  gap: 15px;
  text-align: center;
  padding: 0.7rem;
}

/* pantalla escritorio */
@media (min-width: 768px) {
  .containerDetailsInt {
  padding-left: 10rem;
  padding-right: 10rem;
  }
  .containerImg{
    width: auto;
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
.containerDetailsInt p,a{
  margin-top: 15px;
  color:#929191 ;
  text-align: justify;

}

  .IconCss{
    font-size: 25px;
    color:#424242;
    margin-top: 7px;
    margin-right: 20px;
  }

.containerImg{
  width: 100%;
  min-height: 30%;
}

.containerImg img{
  width: 100%;
}

.containerDetailsInt a{
text-decoration: none;
padding: 0;
}