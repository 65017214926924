
.titGlobal h1{
    font-size: 1.9rem;
}

.titGlobal h2{
    font-size: 1.5rem;
    text-align: center;
}

.titGlobal h3{
    font-size: 1.2rem;
    text-align: center;
}

.containerForm{
    background-color: white;
    margin: auto;
    border: 2px solid #c2c2c2;
    padding: 10px;
    min-width: 50%;
   
}

/* ESCRITORIO */
@media (min-width: 768px) {
    .containerForm {
        max-width: 10%;
    }
    
}

.titForm{
    margin-top: 15px;
    margin-bottom: 20px;
    color:white
}

.Form{
    margin: 10px 0.5rem 10px 0.5rem;
}

.Field{
    width: 100%;
    margin: 10px auto 10px auto;
    height: 30px;
    padding: 10px;
    border: 2px solid #c2c2c2;
    color: rgb(128, 125, 125);
}

.FieldTextArea{
    width: 100%;
    padding:5px;
    margin: 10px auto 10px auto;
    height: 6rem;
    border: 2px solid #c2c2c2; 
    color: rgb(128, 125, 125);
    
}

.Form button{
    width: 50%;
    margin: 10px auto 10px auto;
    padding: 5px;
}

.textArea Field{
    height: 10rem;
    background-color: aqua;
}

.error{
    color: rgb(241, 118, 118);
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 7px;
}

.buttonForm{
    background-color: white;
    color: black;
    border: 2.3px solid #4CAF50; /* Green */
    padding: 10px;
}

.buttonForm:hover {
    background-color: #4CAF50; /* Green */
    color: white;
}

.buttonForm {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.labelForm{
    font-size: 13px;
    color:#848484;
    margin: auto;
}

.containerTitForm{
    background-color: #e9e7e7;
    height: 50px;
    width: 100%;  
}

.containerTitForm h5{
    color:rgb(128, 125, 125);
    text-align: center;
    margin: auto;
    padding: 15px;
}

.mediosContact{
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.mediosContact h3{
color: black;
text-align: center;
}


.iconoContact {
    height: 10rem;
}

.textContact{
    color: #706f6f;
    font-size: 1.2rem;
}

.containerText{
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}

.containerText p{
    text-align: justify;
    color:#5a5959
}