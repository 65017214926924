.classContainerInvBolsa img {
    display: block;
    margin: auto;
    padding: 0.7rem;
    width: 99%;
  }

  
  .classContainerInvBolsa {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    text-align: justify;
    width: 100%;
    line-height: 2rem;
    color: rgb(139, 139, 137);
  }
  

  /* PANTALLA GRANDE */

@media (min-device-width : 1068px) {
    .classContainerInvBolsa {
      padding-left: 13rem;
      padding-right: 13rem;
      padding-top: 2rem;
      text-align: justify;
      width: 100%;
      line-height: 2rem;
      color: rgb(139, 139, 137);
    }
    .classContainerInvBolsa img {
      display: block;
      margin: auto;
      padding: 1rem;
      width: 75%;
      height: 40rem;
    }
  }
  
  .blackP{
    font-weight: bold;
  }

  
  .containerCompRedes{
    text-align: center;
    margin-top: 1.3rem;
  }


  .IconComp{
    color: rgb(77, 76, 76);
    font-size: 5rem;
    margin-top: 1.3rem;
    margin-bottom: 1rem;
}