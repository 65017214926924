

.classContainerVar span {
  font-size: 1.1rem;
  font-weight: bold;
}

.classContainerVar {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  text-align: justify;
  width: 100%;
  line-height: 2rem;
  color: rgb(139, 139, 137);
}

.classContainerVar img {
  display: block;
  margin: auto;
  padding: 0.7rem;
  width: 99%;
}

/* ESCRITORIO HORIZONTAL*/
@media (min-device-width : 1068px){
  .classContainerVar {
    padding-left: 13rem;
    padding-right: 13rem;
    padding-top: 2rem;
    text-align: justify;
    width: 100%;
    line-height: 2rem;
    color: rgb(139, 139, 137);
   
  }
  .classContainerVar img {
    display: block;
    margin: auto;
    padding: 1rem;
    width: 75%;
    height: 45rem;
  }
}

.containerCompRedes1{
  text-align: center;
}

.containerCompRedes1 h3{
  color:rgb(139, 139, 137);
  margin-bottom: 0

}

.IconComp1{
  color: rgb(77, 76, 76);
  font-size: 5rem;
  margin-bottom: 2.7rem;
  margin-top: 0.1rem;
}

