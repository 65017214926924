.containerGlobalWeb {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.5rem;
  text-align: justify;
  width: 100%;
  line-height: 2rem;
  color: rgb(139, 139, 137);
}

.containerGlobalWeb img {
  display: block;
  margin: auto;
  padding: 0.7rem;
  width: 99%;
}

.titGral{
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}
/* PANTALLA ESCRITORIO */

@media (min-device-width: 1068px) {
  .containerGlobalWeb {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 2rem;
    text-align: justify;
    width: 100%;
    line-height: 2rem;
    color: rgb(139, 139, 137);
  }
  .containerGlobalWeb img {
    display: block;
    margin: auto;
    padding: 1rem;
    width: 90%;
    height: 40rem;
  }
  .containerForm {
    padding: 1rem;
    border: 0;
  }
  
  .imgGral img{
    width: 60%;
  }

  .imgGral2 img{
    width: 30%;
    height: 10%;
  }

}

.containerCard {
  display: flex;
  grid-template-columns: auto auto;
  gap: 10px;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.5 rem;
  margin-top: 1.5 rem;
  padding: 1.5 rem;
}

.containerCard > * {
  margin: 0 10px; /* Ajusta el espaciado entre los botones según sea necesario */
}

.containerForm {
  border: 0cap;
}

.imgCard {
  height: 20rem;
}

/* MOBILE */

@media (max-width: 768px) {
  .cardEditAddService {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 15px;

    /* sirve para centrar los elementos cuando el contenedor tiene el display:grid
    como en este caso */
    place-items: center;
  }

  .containerCard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 15px;

    /* sirve para centrar los elementos cuando el contenedor tiene el display:grid
    como en este caso */
    place-items: center;
  }
}

.fixed-width-card{
  width: 60rem;
}

.iconButton img{
  width: 25%;
  height: auto;
}

.iconPaypal{
  font-size: 1rem;
  width: auto;
}

.imgFondo{
  background-image: url('../icons/fondoHome.jpg'); /* Cambia 'ruta/a/tu-imagen.jpg' por la ruta real de tu imagen */
  background-size: cover; /* Hace que la imagen cubra todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  height: 100%; /* Ajusta la altura según sea necesario */
  width: 100%; /* Ajusta el ancho según sea necesario */
}