/* Services.css */
.imgSist img {
  width: 100%;
  object-fit: cover;
  display: block;
}


@media (max-width: 768px) {

  .containerVideo iframe{
    width: 100% !important;
    height: 250px !important;
  }

}
